<template>
    <div class="fee-modal-container">
        <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
        <header id="modalTitle" class="modal-header">
            <slot name="header">
                <div class="fees-header">
                    <h1>Les tarifs</h1>
                    <div class="fees-search-container">
                        <input type="text" v-model="searchProductName" v-on:keyup="filterProductsByName" placeholder="Rechercher">
                    </div>
                </div>
            </slot>
        </header>

        <section id="modalDescription" class="modal-body">
            <slot name="body">

                <div class="pill-container" v-if="this.categories.length > 1">
                    <div v-for="(cat) in categories" :key="cat" class="pill" v-on:click="filterProductsByCategory(cat)" :class="selectedCategory == cat ? 'active' : ''">{{ cat }}</div>
                </div>

                <table class="fees">
                    <tr class="table-header">
                        <td>
                            Article
                        </td>
                        <td>Prix unitaire</td>
                    </tr>
                    <tr v-for="(fee, idx) in fees" :key="idx">
                        <td>
                            {{ fee.itemDescription }}
                        </td>
                        <td>
                            {{ fee.itemFee }} &euro;
                        </td>
                    </tr>
                </table>
            </slot>
        </section>

        <!--<footer class="modal-footer">
            <button type="button" class="btn btn-primary" @click="closeModal" aria-label="Close modal">Close Modal</button>
        </footer>-->
    </div>
</template>

<script>
export default {
    data() {
        return {
            categories: [],
            selectedCategory: 0,
            searchProductName: '',
            fees: []
        }
    },
    props: ['params'],
    emits: [
        'close'
    ],
    methods: {
        closeModal() {
            this.$emit('close');
        },
        filterProductsByCategory(category) {
            if (this.searchProductName.length > 0) {
                this.fees = this.params.fees.filter(elt => elt.category == category && elt.itemDescription.toLowerCase().includes(this.searchProductName.toLowerCase()))
            } else {
                this.fees = this.params.fees.filter(elt => elt.category == category)
            }
            this.selectedCategory = category
        },
        filterProductsByName() {
            this.fees = this.params.fees.filter(elt => elt.category == this.selectedCategory && elt.itemDescription.toLowerCase().includes(this.searchProductName.toLowerCase()))
        }
    },
    mounted() {
        for (let [idx, fee] of this.params.fees.entries()) {
            if (!this.categories.includes(fee.category)) {
                this.categories.push(fee.category)
            }
        }
        if (this.categories.length > 0) {
            this.selectedCategory = this.categories[0]
            this.fees = this.params.fees.filter(elt => elt.category == this.selectedCategory)
        } else {
            this.fees = this.params.fees
        }
    }
}
</script>

<style lang="scss" scoped>

    header {
        margin-top: 35px;
        padding: 15px 0;
        width: 100%;
    }

    .fees-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .fees-search-container {
        background-color: #222;
        width: 180px;
        border-radius: 25px;
        padding: 4px 8px;
    }

    .fees-search-container input {
        color: white;
        background-color: transparent;
        border: none;
        outline: none;
        width: 90%;
        margin: 0 auto;
        font-size: 18px;
    }

    .fee-modal-container {
        padding: 20px 40px;
    }

    .btn-close {
        position: absolute;
        top: 20px;
        left: 20px;
        right: auto;
        font-weight: 400;
        background-color: #f6f5f4;
        border-radius: 50%;
        padding: 4px 10px 6px 10px;
    }
    .modal-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .modal-body {
        text-align: center;
        p {
            text-align: left;
            padding: 10px 30px;
        }
    }

    table {
        border: none;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        border-collapse:collapse;
        border-spacing: 0;
    }

    tr {
        border-bottom: solid 1px #e9e8e6;
    }

    tr.table-header td {
        font-weight: bolder;
        font-size: 22px;
        padding-bottom: 12px;
    }

    th {
        font-weight: bold;
        border: none;
        border-bottom: solid 1px #e9e8e6;
    }

    tr td:first-of-type{
        text-align: left;
    }

    tr td:last-of-type{
        text-align: right;
    }

    tr:nth-child(even) {
        background-color: #f6f5f4;
    }

    td {
        /* border: solid 1px lightgray; */
        box-sizing: border-box;
        padding: 4px 10px;
        margin: 0;
        border-collapse: collapse;
    }

    .pill-container {
        display: inline-block;
        margin: 0 auto 40px 0;
        background-color: #F6F5F4;
        border-radius: 20px;
        justify-content: center;
    }

    .pill {
        display: inline-block;
        padding: 8px 32px;
        border-radius: 20px;
        background-color: #F6F5F4;
        &.active {
            color: white;
            background-color: #2B3138;
        }
    }

    .pills {
        padding: 6px 10px;
        background-color: #edece9;
        border-radius: 25px;
    }

    .pill {
        padding: 4px 16px;
        display: inline-block;
        color: #6e6e6e;
    }

    .pill:first-of-type {
        border-radius: 25px 0 0 25px;
    }

    .pill:last-of-type {
        border-radius: 0 25px 25px 0;
    }

    .pill.active {
        background-color: #00ddb7;
        font-weight: bolder;
        color: black;
        border-radius: 25px;
    }

    @media screen and (max-width : 980px) {

        .fees-search-container {
            width: 100px;
            padding: 2px 4px;
        }

        .fees-search-container input {
            width: 96%;
            margin: 0 auto;
            font-size: 14px;
        }

        .pill{
            padding: 12px 16px;
        }
    }

</style>