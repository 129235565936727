<template>
    <div>
        <!--<header id="modalTitle" class="modal-header">
            <slot name="header">
                Calendly
            </slot>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
        </header>-->

        <section id="modalDescription" class="modal-body" style="height: 88vh">
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
            <iframe :src="params.calendlyUrl" frameborder="0" width="100%" height="100%"></iframe>
        </section>
    </div>
</template>

<script>
export default {
    emits: [
        'close'
    ],
    props: [
        'isShowModal',
        'params',
        'modalComponent',
        'modalClass'
    ],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    }
}
</script>

<style lang="scss" scoped>
.btn-close {
    z-index: 10;
    color: white;
    background-color: black;
    padding: 8px 15px;
    border-radius: 50%;
    top: 4px;
    right: 4px;
}
</style>