<template>
    <div id="fidensio-app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  data() {
      return {

      };
  },
  methods: {

  }
}
</script>

<style>
#fidensioa-app {
    width: 100%;
    height: 100%;
}
</style>