<template>
    <div>
        <header id="modalTitle" class="modal-header">
            <slot name="header">
                This is the default title!
            </slot>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
        </header>

        <section id="modalDescription" class="modal-body">
            <slot name="body">
                This is the default body!
            </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                This is the default footer!
            </slot>
            <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">Close Modal</button>
        </footer>
    </div>
</template>

<script>
export default {
  emits: [
    'close'
  ],
  methods: {
    closeModal() {
      this.$emit('close');
    },
  }
}
</script>