<template>
    <div>
        <transition name="modalfade">
            <div class="modal-backdrop" v-show="isShowModal" v-on:click="close" />
        </transition>
        <div v-show="isShowModal" class="modal-flex-container" :class="modalContainerClass">
            <transition :name="slide">
                <div v-show="isShowModal" class="modal" :class="modalClass" :style="style" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                    <component @close="close" :params="params" :is="modalComponent" v-bind="params" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import AddressForm from "@/components/modals/AddressForm";
import PressingAtHomeDelivery from "@/components/modals/ModalPressingAtHomeDelivery";
import Calendly from "@/components/modals/Calendly";
import ModalInformation from "@/components/modals/ModalInformation";
import QrCode from "@/components/modals/ModalQrCode";
import OrderConfirmation from "@/components/modals/ModalOrderConfirmation";
import ServiceCreditCard from "@/components/modals/ModalServiceCreditCard";
import ServiceAddress from "@/components/modals/ModalServiceAddress";
import ServiceFees from "@/components/modals/ModalServiceFees";
import ServiceProvider from "@/components/modals/ModalServiceProvider";

  export default {
    components: { AddressForm, ModalInformation, PressingAtHomeDelivery, ServiceCreditCard, ServiceAddress, ServiceFees, ServiceProvider, Calendly, QrCode, OrderConfirmation },
    props: [
      'isShowModal',
      'params',
      'modalComponent',
      'modalClass'
    ],
    computed: {
        modalContainerClass() {
            // console.log(this.modalClass)
            if (!this.modalClass || (this.modalClass != "center" && this.modalClass != "center-small")) {
                return "modal-flex-container-sidebar"
            }
            return ""
        },
        style() {
            if (this.modalClass && this.modalClass == 'info') {
                return "right: 0; width: 35%"
            }
            if (this.modalClass && this.modalClass == 'center') {
                return "width: 90vw; height: 90vh; margin: 5vh 5vw"
            }
            /* if (this.modalClass && this.modalClass == 'info-large') {
                return "right: 0; width: 65%"
            } */
            /* if (this.modalClass && this.modalClass == 'center-small') {
                return "width: 50vw; height: 50vh; margin: 25vh 25vw; min-height: 600px; min-width: 600px; border-radius: 50px;"
            } */
            /* return "left:0; width: 35%" */
            return ''
        },
        slide() {
            if (this.modalClass && this.modalClass == 'info') {
                return "modalslideright"
            }
            if (this.modalClass && this.modalClass == 'info-large') {
                return "modalslideright"
            }
            return "modalslideleft"
        }
    },
    watch: {
        isShowModal(newVal, oldVal) {
            if (oldVal == false && newVal == true) {
                document.body.classList.add('openedModal');
                // When the modal is shown, we want a fixed body
                document.body.style.position = 'fixed';
                document.body.style.top = `-${window.scrollY}px`;
            }
            if (oldVal == true && newVal == false) {
                document.body.classList.remove('openedModal');
                // When the modal is hidden, we want to remain at the top of the scroll position
                document.body.style.position = '';
                document.body.style.top = '';
            }
        }
    },
    methods: {
        close() {
            document.body.classList.remove('openedModal');
            this.$emit('close');
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
      },
    }

  };
</script>


<style lang="scss">
.modalfade-enter-from,
.modalfade-leave-to {
    opacity: 0;
}

.modalfade-enter-active,
.modalfade-leave-active {
    transition: opacity .5s ease;
}

.modalslideleft-leave-active,
.modalslideleft-enter-active {
    transition: all 0.5s ease;
}
.modalslideleft-enter-from {
    transform: translateX(-100%);
}
.modalslideleft-leave-to {
    transform: translateX(-100%);
}

.modalslideright-leave-active,
.modalslideright-enter-active {
    transition: all 0.5s ease;
}
.modalslideright-enter-from {
    transform: translateX(100%);
}
.modalslideright-leave-to {
    transform: translateX(100%);
}

.modal-backdrop {
    z-index: 99;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    /*display: flex;
    justify-content: center;
    align-items: center; */
}


.modal-flex-container {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-flex-container-sidebar {
    justify-content: flex-start;
}

.modal {
    z-index: 101;
    width: 30%;
    position: absolute;
    padding: 0;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.modal.center-small {
    padding: 0px 40px;
    width: fit-content;
    height: fit-content;
    border-radius: 50px;
    position: static;
    .modal-container {
        padding: 20px 40px;
    }
    .icon-close {
        top: 20px;
        right: 0px;
    }
}

.modal.info-large {
    right: 0;
    width: 65%
}

.modal-container {
    position: relative;
    padding: 10px 20px;
    .icon-close {
        border: none;
        border-radius: 50%;
        padding: 10px 14px;
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
    }
    h1 {
        margin: 0;
        font-family: Texta-Heavy;
        color: $c-0-1000;
        display: inline-block;
        width: fit-content;
        padding-bottom: 12px;
        border-bottom: solid 2px black;
    }
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    box-sizing: border-box;
    /* color: #4AAE9B; */
    justify-content: space-between;
}

.modal-footer {
    text-align: center;
    padding-top: 40px;
}

.footer-buttons {
    align-items: center;
}

.modal-body {
    position: relative;
    /* padding: 20px 10px; */
}

.btn {
    margin: 0 auto;
}

.btn-close {
    position: absolute;
    top: 20px;
    right: 0px;
    border: none;
    line-height: 24px;
    font-size: 30px;
    cursor: pointer;
    color: black;
    background-color: transparent;
}

@media screen and (max-width: 980px) {
    .modal {
        position: absolute;
        padding: 0;
        width: 100vw;
        height: 100vh;
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .btn-close {
        top: 25px;
        right: 20px;
    }

    .header {
        padding: 0 20px;
    }

    .modal.center-small {
        padding: 0 20px;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        .modal-container {
            padding: 0;
            .icon-close {
                right: 0px;
            }
        }
    }

    .modal.info-large {
        padding: 0 20px;
        width: 100vw;
        height: 100vh;
        border-radius: 0;
        .modal-container {
            padding: 0;
            .icon-close {
                right: 0px;
            }
        }
    }

    .modal-container {
        padding: 0;
        .icon-close {
            z-index: 102;
            top: 10px;
            right: 10px;
        }
    }


    .modal-footer {
        padding-top: 15px;
    }
}


</style>

