<template>
    <div class="modal-container">
        <header class="modal-header">
            <slot name="header">
                <h1>Carte Bancaire</h1>
                <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
            </slot>
        </header>

        <section class="modal-body">
            <slot name="body">
                <ul>
                    <li v-for="card in cards" :key="card.id" class="card" :class="card.id == selectedCard.id ? 'selected' : ''" v-on:click="selectCard(card.id)">
                        <span>
                            <font-awesome-icon icon="credit-card" />
                            <span>XXXX XXXX XXXX {{ card.last4  }}</span>
                        </span>
                        <font-awesome-icon icon="check" v-if="card.id == selectedCard.id" class="white" />
                    </li>
                </ul>
            </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                <router-link to="/account/bankcards">Gérer vos enregistrements de carte bancaire</router-link>
            </slot>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ['params'],
    emits: [
        'close'
    ],
    computed: mapState({
        // user: state => state.user.user,
        cards: state => state.user.cards,
        selectedCard: state  => state.user.selectedCard
    }),
    methods: {
        ...mapActions({
            updateSelectedCard: 'user/selectCard'
        }),
        closeModal() {
            this.$emit('close');
        },
        selectCard(cardId) {
            let tmpCards = this.cards.filter(c => c.id == cardId)
            this.updateSelectedCard(tmpCards[0])
            this.$emit('close')
        }
    },
    mounted() {
        console.log(this.cards)
    }
}
</script>

<style lang="scss" scoped>
    table {
        border: solid 1px lightgray;
        box-sizing: border-box;
        border-collapse: collapse;
    }
    td {
        border: solid 1px lightgray;
        padding: 4px 10px;
        margin: 0;
        border-collapse: collapse;
    }
    li.card {
        display: flex;
        justify-content: space-between;
        svg {
            margin-right: 10px;
        }
        text-align: left;
        background-color: #F3F1F0;
        border-radius: 15px;
        width: 94%;
        padding: 16px 20px;
        margin: 16px auto;
    }
    li.card:hover,
    li.card.selected {
        background-color: #AEEBE0;
        border: solid 1px #00C3A0
    }
    .white {
        color: white;
    }
</style>