import { createApp } from 'vue'
import mitt from 'mitt';
import VueAxios from 'vue-axios'
// import axios from 'axios'
import axios from './plugins/axios';
import moment from 'moment'
import router from './router'
import store from './store'
// import { createDynamicForms } from '@asigloo/vue-dynamic-forms';
import VueTelInput from 'vue-tel-input';
import { makeServer } from "./mock/server"
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGoogleMaps from '@fawmi/vue-google-maps';
import App from './pages/App.vue';
import VueSelect from 'vue-select';

// Icons
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { faPhone } from "@fortawesome/free-solid-svg-icons";
// import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// library.add(faPhone);
// library.add(faCalendar);

// Add all icons to the library so you can use it in your page
library.add(fas, far)

import ErrorComponent from '@/components/Error.vue'
import ModalComponent from '@/components/Modal.vue';
import HeaderComponent from '@/components/Header';
import FooterComponent from '@/components/Footer';

// Error messages
import errorMessages from "./i18n/errors.json"

if (process.env.VUE_APP_ENV === "local" && process.env.VUE_APP_USE_MOCK === "true") {
  makeServer()
}

const emitter = mitt();

/* const VueDynamicForms = createDynamicForms({
    // Global Options go here
  }); */
const paramsVueGoogleMaps = {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: 'places'
    }
}

/* const app = new Vue({
    el: '#app',
    router:router,
    store:store
}); */

// axios.defaults.withCredentials = true
// axios.defaults.baseURL = process.env.VUE_APP_OURANOS_URL

console.log(errorMessages)
axios.interceptors.response.use(
  response => response,
  error => {
    // let errorMessage = "Une erreur serveur est survenue"
    let errorMessage = errorMessages["0"]
    if (error.response.data.type == 1) {
      // global error'
      if (errorMessages[error.response.data.type.toString()][error.response.data.error.toString()]) {
        errorMessage = errorMessages[error.response.data.type.toString()][error.response.data.error.toString()]
      }
    } else {
      // specific error
      if (errorMessages[error.response.data.type.toString()][error.response.data.category][error.response.data.error.toString()]) {
        errorMessage = errorMessages[error.response.data.type.toString()][error.response.data.category][error.response.data.error.toString()]
      }
    }
    console.log(errorMessage)
    emitter.emit('error', errorMessage)
    throw (error)
  }
);

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    //.use(VueDynamicForms)
    .use(VueTelInput)
    .use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA })
    .use(VueGoogleMaps, paramsVueGoogleMaps)
    // .mount('#app')

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.moment=moment;

app.config.errorHandler = (err) => {
  emitter.emit('error', 'erreur globale')
  console.log(err.stack)
  console.error("error caught by vuejs globally " + err)
}
app.component("font-awesome-icon", FontAwesomeIcon)
app.component('Error', ErrorComponent)
app.component('Modal', ModalComponent)
app.component('Header', HeaderComponent)
app.component('Footer', FooterComponent)
app.component('v-select', VueSelect)
app.mount('#app')


