<template>
    <footer>
        <ul class="navbar-nav">
            <li class="nav-item" :class="linkClass('services')">
                <router-link :to="{path:'/services/list'}" class="nav-btn-link">
                    <ServiceIcon class="icon-nav"/>
                    <p class="nav-link">Vos Services</p>
                </router-link>
            </li>
            <li class="nav-item" :class="linkClass('orders')">
                <router-link :to="{path:'/orders'}" class="nav-btn-link">
                    <OrderIcon class="icon-nav"/>
                    <p class="nav-link">Vos demandes</p>
                </router-link>
            </li>
            <li class="nav-item" :class="linkClass('account')">
                <router-link :to="{path:'/account'}" class="nav-btn-link">
                    <UserIcon class="icon-nav"/>
                    <p class="nav-link">Votre compte</p>
                </router-link>
            </li>
        </ul>
    </footer>
</template>

<script>
import UserIcon from "@/components/Icon/UserIcon";
import OrderIcon from "@/components/Icon/OrderIcon";
import ServiceIcon from "@/components/Icon/ServiceIcon";

export default {
    components: { UserIcon, OrderIcon, ServiceIcon },
        data(){
        return {}
    },
    methods: {
        linkClass(section) {
            switch(section) {
                case 'services':
                    if (this.$route.path == '/') return 'active'
                    if (this.$route.path.includes('/service')) return 'active'
                    return ''
                case 'orders':
                    if (this.$route.path.includes('/orders')) return 'active'
                    return ''
                case 'account':
                    if (this.$route.path.includes('/account')) return 'active'
                    return ''
            }
            return ''
        }
    }
}
</script>

<style lang="scss">
footer {
    display: none;
}

.active {
    .nav-btn-link .cicle-icon-nav {
        background-color: $c-2-300;
    }
}

/* iPads (landscape) ----------- */
@media only screen and (max-device-width : 1280px) {

}

/* iPads (portrait) ----------- */
@media screen and (max-width : 980px) {
    footer {
        display: block;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 80px;
        background-color: white;
        padding: 0 3%;
        border-top: solid 1px $c-3-500;
        box-shadow: 0px 0px 10px $c-3-400;
        z-index: 10;

        ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }

    .nav-btn-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:hover {
            .cicle-icon-nav {
                height: 36px;
                width: 36px;
                border: 1px solid $c-2-400;
                background-color: $c-2-300;
                box-sizing: border-box;
                margin-right: 12px;
                .icon {
                    height: 14px;
                    stroke: $c-0-800;
                }
            }
        }
    }
}

/* Smartphones (portrait) ----------- */
@media screen and (max-width : 768px) {

}

</style>