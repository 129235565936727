<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="test-icon" version="1.1" id="Calque_1" x="100%" y="100%" viewBox="0 0 27.2 27.2" style="enable-background:new 0 0 27.2 27.2;" xml:space="preserve">
        <g id="Icon_feather-info" transform="translate(25.906 25.906) rotate(180)">
            <path id="Tracé_17" class="st0" d="M24.7,12.3c0,6.8-5.5,12.3-12.3,12.3S0,19.1,0,12.3C0,5.5,5.5,0,12.3,0S24.7,5.5,24.7,12.3   L24.7,12.3z"/>
            <path id="Tracé_18" class="st0" d="M12.3,17.3v-4.9"/>
            <path id="Tracé_19" class="st0" d="M12.3,7.4L12.3,7.4"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'InformationIcon'
    }
</script>

<style lang="scss" scoped>
    .test-icon {
        fill:none;
        stroke:#000000;
        stroke-width:2.5;
        stroke-linecap:round;
        stroke-linejoin:round;
    }
</style>