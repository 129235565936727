<template>
    <div class="modal-container">
        <button v-on:click="closeModal" class="icon-close" aria-label="Close modal">
            <font-awesome-icon icon="times" />
        </button>
        <header id="modalTitle" class="modal-header">
            <slot name="header">
                <h1>Votre QRCode</h1>
            </slot>
        </header>

        <section id="modalDescription" class="modal-body">
            <slot name="body">
                <h2>Scannez ce badge pour accéder à vos casiers</h2>
                <qrcode-vue :value="user.locker.qrCode" size="300" level="H" />
                <div class="qrcode-number">{{ user.locker.qrCodeId  }}</div>
            </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                <!--Votre QRcode vous permet de vous connecter sur vos casiers-->
            </slot>
            <!--<button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">Close Modal</button>-->
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import QrcodeVue from 'qrcode.vue'
export default {
    components: { QrcodeVue },
    emits: [
        'close'
    ],
    computed: mapState({
        user: state => state.user.user,
        cards: state => state.user.cards
    }),
    methods: {
        closeModal() {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>
.qrcode-number {
    margin-top: 40px;
    font-size: 2em;
    letter-spacing: 15px;
}
.modal-footer {
    display: none;
}
</style>