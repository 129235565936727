<template>
    <div>
        <transition name="modalslide">
            <div v-show="isVisible" class="banner" :class="className" role="dialog" aria-labelledby="errorMessage">
                <InformationIcon class="error-icon" v-if="className == 'error'"/>
                <InformationIcon class="success-icon" v-if="className == 'success'"/>
                <p class="error-message">{{ message }}</p>
            </div>
        </transition>
    </div>
</template>

<script>
import InformationIcon from "@/components/Icon/InformationIcon";

export default {
    components: {InformationIcon},
    data(){
        return {
            'isVisible': false,
            'message': '',
            'className': ''
        }
    },
    mounted() {
        this.emitter.on("error", (message) => {
            this.showError(message)
        });
        this.emitter.on("success", (message) => {
            this.showSuccess(message)
        });
    },
    methods: {
        showError(message) {
            this.isVisible = true
            this.className = 'error'
            this.message = message
            setTimeout(this.hideMessage, 6000)
        },
        showSuccess(message) {
            this.isVisible = true;
            this.className = 'success'
            this.message = message
            setTimeout(this.hideMessage, 6000)
        },
        hideMessage() {
            this.isVisible = false
            this.message = ''
        },
    }
};
</script>


<style lang="scss" scoped>

    .modalslide-leave-active,
    .modalslide-enter-active {
        transition: all 0.5s ease;
    }
    .modalslide-enter-from {
        transform: translateY(-100%);
    }
    .modalslide-leave-to {
        transform: translateY(-100%);
        .error-icon,
        .success-icon {
            opacity: 0;
        }
    }

    .banner {
        position: absolute;
        top: 0;
        left: 0;
        height: 55px;
        width: 100%;
        overflow-x: auto;
        z-index: 100;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3%;
        .error-message{
            padding-bottom: 5px;
        }
        .error-icon,
        .success-icon {
            width: 24px;
            height: 24px;
            rotation: 180deg;
            margin-right: 22px;
            stroke: $c-0-0;
        }
    }

    .error {
        background: #DE473A;
    }

    .success {
        background: #1ea22d;
    }
</style>

