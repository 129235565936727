<template>
    <div>
        <header id="modalTitle" class="modal-header">
            <slot name="header">
                <h1>{{ params.title }}</h1>
                <h2>{{ params.subtitle }}</h2>
            </slot>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
        </header>

        <section id="modalDescription" class="modal-body">
            <slot name="body">
                <img v-if="params.providerImg" :src="require('@/assets/img/Services/providers/' + params.providerImg)" alt="">
                <h1 v-else>{{ params.providerName }}</h1>
                <p>
                    {{ params.providerDescription }}
                </p>
            </slot>
        </section>

        <footer class="modal-footer">
            <!--<slot name="footer">
                This is the default footer!
            </slot>-->
            <button type="button" class="btn btn-primary" @click="closeModal" aria-label="Close modal">Fermer</button>
        </footer>
    </div>
</template>

<script>
export default {
    props: ['params'],
    emits: [
        'close'
    ],
    methods: {
        closeModal() {
            this.$emit('close');
        },
    }
}
</script>

<style lang="scss">
.modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.modal-body {
    text-align: center;
    p {
        text-align: left;
        padding: 10px 30px;
    }
}
</style>