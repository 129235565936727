<template>
    <div class="cicle-icon-nav">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" x="100%" y="100%" viewBox="0 0 15.9 17.7" style="enable-background:new 0 0 15.9 17.7;" xml:space="preserve">
            <g id="Groupe_921" transform="translate(1 1)">
                <g id="Icon_feather-user" transform="translate(0 0)">
                    <path id="Tracé_10" class="st0" d="M13.9,15.7v-1.8c0-1.9-1.5-3.5-3.5-3.5c0,0,0,0,0,0h-7c-1.9,0-3.5,1.6-3.5,3.5c0,0,0,0,0,0    v1.8H13.9"/>
                    <path id="Tracé_11" class="st0" d="M10.5,3.5c0,1.9-1.6,3.5-3.5,3.5S3.4,5.5,3.4,3.5C3.4,1.6,5,0,7,0C8.9,0,10.5,1.6,10.5,3.5    C10.5,3.5,10.5,3.5,10.5,3.5z"/>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'UserIcon',
}
</script>

<style lang="scss" scoped>
.cicle-icon-nav {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon{
    fill:none;
    stroke:#000000;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    height: 17px;
}
</style>