<template>
    <div class="modal-container">
        <button v-on:click="closeModal" class="icon-close" aria-label="Close modal">
            <font-awesome-icon icon="times" />
        </button>
        <header id="modalTitle" class="modal-header">
            <slot name="header">

            </slot>
        </header>

        <section id="modalDescription" class="modal-body">
            <slot name="body">
                <div class="validationIcon">
                    <font-awesome-icon icon="check" />
                </div>
                <h2>Demande validée</h2>
                <p class="body-text">
                    Vous avez reçu la confirmation de votre commande par email
                    <br>
                    et je m'active pour traiter votre demande.
                </p>
                <div class="buttons">
                    <button class="confirm" v-on:click="closeModal">OK</button>
                </div>
            </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                <!--Votre QRcode vous permet de vous connecter sur vos casiers-->
            </slot>
            <!--<button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">Close Modal</button>-->
        </footer>
    </div>
</template>

<script>
export default {
    emits: [
        'close'
    ],
    methods: {
        closeModal() {
            this.$emit('close');
            this.$router.push('/orders')
        },
    }
}
</script>

<style scoped>
.validationIcon {
    margin: 0 auto;
    border-radius: 50%;
    background-color: #00C3A0;
    width: 132px;
    height: 132px;
    font-size: 120px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

p.body-text {
    text-align: center;
}

div.buttons {
    margin-top: 25px;;
}

button.confirm {
    background-color: #00C3A0;
    color: white;
    font-weight: bold;
    padding: 8px 24px;
    border: none;
    border-radius: 15px;
}

.modal-footer {
    display: none;
}
</style>