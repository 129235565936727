import axios from "../plugins/axios";

export default {
    namespaced: true,
    state:{
        authenticated:false,
    },
    getters:{
        authenticated(state) {
            return state.authenticated === true
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        }
    },
    actions:{
        async authenticate({commit, dispatch}, credentials) {
            if (process.env.VUE_APP_ENV == 'local') {
                credentials.tokenCaptcha = 'validToken'
            }

            if (process.env.VUE_APP_ENV != 'local') {
                await axios.get(process.env.VUE_APP_OURANOS_URL.replace('spa', '') + 'sanctum/csrf-cookie')
            }

            try {
                // Authenticate the user
                let user = await axios.post(process.env.VUE_APP_OURANOS_URL + '/login', credentials);
                if (!user || !user.data.id) {
                    throw new Error("Authentication failure")
                }

                commit('SET_AUTHENTICATED',true)
                dispatch('user/update', user.data, {root: true})
                // dispatch('user/setSSO', user.isSSOUser, {root: true})

                // Get user addresses and services
                // await this.loadAddresses(user)
                dispatch('user/loadAddresses', null, {root: true})
                dispatch('user/loadBankCards', null, {root: true})

                // Get news
                let news = await axios.get(`${process.env.VUE_APP_OURANOS_URL}/company/${user.data.companyId}/actuality`)
                news = news.data;
                // console.log(news)
                // console.log('news are ready')
                dispatch('user/setCompanyNews', news, {root: true})
                return true
            } catch (error) {
                console.log(error)
                return error
            }
        },
        signin({commit}) {
            commit('SET_AUTHENTICATED',true)
        },
        login({commit}){
            commit('SET_AUTHENTICATED',true)
        },
        logout({commit}){
            commit('SET_AUTHENTICATED',false)
        }
    }
}