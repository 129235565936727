import axios from 'axios';

axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_OURANOS_URL

/* axios.interceptors.request.use(
  (request) => {
    // eslint-disable-next-line no-param-reassign
    request.config = {
      ...(request.config ?? {}), // preserve a given request.config object
      start: Date.now(),
    };
    return request;
  },
); */

axios.interceptors.response.use(
  (response) => {
    // const now = Date.now();
    // console.info(`Api Call ${response.config.url} took ${now - response.config.config.start}ms`);
    // console.log("we are in the plugin file")
    return response;
  },
  (error) => {
    console.log("there is a global axios error" + (error.response ? error.response.status : ''))
    if (error.response && error.response.status == 401) {
      window.location.href = '/logout'
    }
    throw error
  }
);

export default axios;