<template>
    <div class="modal-container">
        <button @click="closeModal" class="icon-close">
            <font-awesome-icon icon="times" />
        </button>
        <div class="header">
            <h1 v-if="addressId">Modifier une address</h1>
            <h1 v-else>Ajoutez une adresse</h1>
        </div>
        <form class="form-container">
            <div class="form-block">
                <div class="form-item">
                    <label class="form-label" for="firstName">Nom de l'adresse</label>
                    <input id="name" name="name" v-model="place.name" type="text" placeholder="Domicile, Maison de vacances, ..." class="form-control" :class="v$.place.name.$errors.length > 0 ? 'form-error' : ''" required>
                </div>
            </div>
            <div class="form-block">
                <div class="form-item">
                    <label for="address">Addresse</label>
                    <GMapAutocomplete type="text" placeholder="15 rue du parc" :value="initialAddressValue" class="form-control" @place_changed="setPlace" :class="v$.place.street.$errors.length > 0 ? 'form-error' : ''" required />
                </div>
            </div>
            <div class="form-block">
                <div class="form-item">
                    <label for="additional">Complément d'adresse</label>
                    <textarea name="additional" id="additional" cols="30" rows="10" placeholder="Batiment, etage, digit code, etc..." v-model="additional"></textarea>
                </div>
            </div>
        </form>
        <div class="footer-buttons" :class="addressId ? ' footer-buttons-edit' : ' footer-buttons-add'">
            <button v-if="addressId" class="cancel-button btn-secondary" v-on:click="closeModal">Annuler</button>
            <button class="submit-button btn-primary" v-on:click="submitForm">Enregistrer</button>
        </div>
    </div>
</template>

<script>

// import { TextField, TextAreaField, CustomField, Validator, required } from '@asigloo/vue-dynamic-forms';
import { mapState, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
// import { validAddress } from '@/plugins/vuelidate/addressValidator';

/* const addressValidator = (value) => {
    console.log(value)
    return false
} */

export default {
    props: [
        'params'
    ],
    emits: [
        'close'
    ],
    setup () {
        return { v$: useVuelidate() }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        initialAddressValue() {
            return this.params.address.country ? this.params.address.streetNumber + ' ' + this.params.address.street + ', ' + this.params.address.postalCode + ' ' + this.params.address.locality + ', ' + this.params.address.country : '';
        }
    },
    data() {
        return {
            addressId: 0,
            place: {
                name: "",
                streetNumber: 1,
                street: "",
                postalCode: "",
                locality: "",
                country: ""
            },
            additional: null
        };
    },
    watch: {
        tick: function(value) {
            this.setData(value);
        },
        params: function (value) {
            this.setData(value)
        }
    },
    validations () {
        return {
            place: {
                name: { required },
                street: { required }
            }
        }
    },
    mounted() {
        this.setData(this.params)
    },
    methods: {
        ...mapActions({
            updateUserAddress: 'user/updateAddress'
        }),
        setData(value) {
            console.log(value)
            this.addressId = value.addressId;
            this.place.name = value.address.name;
            this.place.streetNumber = value.address.streetNumber;
            this.place.street = value.address.street;
            this.place.postalCode = value.address.postalCode;
            this.place.locality = value.address.locality;
            this.place.country = value.address.country;
            this.additional = value.additional
        },
        closeModal() {
            this.$emit('close');
        },
        setPlace(value) {
            this.place.streetNumber = this.getAddressType(value, 'street_number');
            this.place.street = this.getAddressType(value, 'route');
            this.place.postalCode = this.getAddressType(value, 'postal_code');
            this.place.locality = this.getAddressType(value, 'locality');
            this.place.country = this.getAddressType(value, 'country');
        },
        getAddressType(object, type) {
            const component = object.address_components.find(c => c.types.indexOf(type) !== -1);
            if (component) {
                return component.long_name
            }
            return ''
        },
        submitForm() {
            this.v$.$validate() // checks all inputs
            if (this.v$.$error) {
                return
            }
            if (this.addressId) {
                this.updateAddress()
            } else {
                this.addAddress()
            }
        },
        addAddress() {
            let data = {
                ...this.place,
            }
            if (this.additional && this.additional.length > 0) {
                data.additional = this.additional
            }
            this.axios.post('/user/' + this.user.id + '/address', data)
            .then(({data})=> {
                this.updateUserAddress(data);
                this.closeModal()
            }).catch(error => {
                console.log(error)
                // this.emitter.emit('error', "Une erreur serveur est survenue lors de l'ajout d'une adresse")
                throw error
            })
        },
        updateAddress() {
            let data = {
                ...this.place,
            }
            if (this.additional && this.additional.length > 0) {
                data.additional = this.additional
            } else {
                data.additional = null
            }
            this.axios.put('/user/' + this.user.id + '/address/' + this.addressId, data)
            .then(({data})=> {
                data.id = this.addressId
                this.updateUserAddress(data);
                this.closeModal()
            }).catch(error => {
                console.log(error)
                this.emitter.emit('error', "Une erreur serveur est survenue lors de l'ajout d'une adresse")
            })
        }
    }
}
</script>

<style scoped lang="scss">

input {
    width: 100%;
}

textarea {
    height: 140px;
}

.footer-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer-buttons-add {
    justify-content: flex-end;
}

.footer-buttons-edit {
    justify-content: space-between;
}

.submit-button {
    width: fit-content;
}

@media screen and (max-width : 980px) {

}


</style>