import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import user from './user'
import company from './company'

// Vue.use(Vuex)

export default createStore({
    plugins:[
        createPersistedState()
    ],
    modules:{
        auth,
        user,
        company
    }
})