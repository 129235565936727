<template>
    <div class="modal-container">
        <header class="modal-header">
            <slot name="header">
                <h1>Adresse</h1>
                <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
            </slot>
        </header>

        <section class="modal-body">
            <slot name="body">
                <ul>
                    <li v-for="address in addresses" :key="address.id" class="address" :class="address.id == selectedAddress.id ? 'selected' : ''" v-on:click="selectAddress(address.id)">
                        <span>
                            <font-awesome-icon icon="location" />
                            <span>
                                {{ address.name }}
                                <br>
                                {{  address.streetNumber }} {{ address.street }}
                                <br>
                                {{  address.postalCode }} {{  address.locality }}
                            </span>
                        </span>
                        <font-awesome-icon icon="check" v-if="address.id == selectedAddress.id" class="white" />
                    </li>
                </ul>
            </slot>
        </section>

        <footer class="modal-footer desktop-only">
            <slot name="footer">
                <router-link to="/account/addresses">Gérer vos addresses</router-link>
            </slot>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ['params'],
    emits: [
        'close'
    ],
    computed: mapState({
        addresses: state => state.user.addresses,
        selectedAddress: state  => state.user.selectedAddress
    }),
    methods: {
        ...mapActions({
            updateSelectedAddress: 'user/selectAddress'
        }),
        closeModal() {
            this.$emit('close');
        },
        selectAddress(addressId) {
            let tmpAddresses = this.addresses.filter(a => a.id == addressId)
            this.updateSelectedAddress(tmpAddresses[0])
            this.$emit('close')
        }
    },
    mounted() {
        console.log(this.addresses)
    }
}
</script>

<style lang="scss" scoped>
    table {
        border: solid 1px lightgray;
        box-sizing: border-box;
        border-collapse: collapse;
    }
    td {
        border: solid 1px lightgray;
        padding: 4px 10px;
        margin: 0;
        border-collapse: collapse;
    }
    li.address {
        display: flex;
        justify-content: space-between;
        svg {
            margin-right: 10px;
        }
        text-align: left;
        background-color: #F3F1F0;
        border-radius: 15px;
        width: 94%;
        padding: 16px 20px;
        margin: 16px auto;
    }
    li.address:hover,
    li.address.selected {
        background-color: #AEEBE0;
        border: solid 1px #00C3A0
    }
    .white {
        color: white;
    }
</style>