// import { state } from "../../../frontend-v2/store/users"
import axios from "axios";
export default {
    namespaced: true,
    state:{
        user:{},
        selectedAddress: {},
        selectedCard: {},
        services: {},
        localizedServices: {},
        localizedServiceIds: [],
        news: {},
        // serviceCategories: [],
        addresses: {},
        cards: {},
        orders: {},
    },
    getters:{
        user(state) {
            return state.user
        },
        services(state) {
            return state.services
        },
        localizedServices(state) {
            return state.localizedServices
        },
        localizedServiceIds(state) {
            return state.localizedServiceIds
        },
        address(state) {
            return state.user.address
        },
        addresses(state) {
            return state.addresses
        },
        cards(state) {
            return state.cards
        },
        orders(state) {
            return state.orders
        },
        selectedAddress(state) {
            return state.selectedAddress
        },
        selectedCard(state) {
            return state.selectedCard
        }
    },
    mutations:{
        SET_USER (state, value) {
            state.user = value
        },
        SET_USER_ADDRESS(state, address) {
            for (let i = 0 ; i < state.addresses.length; i++) {
                if (state.addresses[i].id == address.id) {
                    state.addresses[i] = address
                    return
                }
            }
            state.addresses.push(address)
        },
        SET_USER_DEFAULT_ADDRESS(state, address) {
            state.user.address = address
        },
        SET_USER_ADDRESSES(state, addresses) {
            state.addresses = addresses
        },
        DEL_USER_ADDRESS(state, address) {
            for (let i = 0 ; i < state.addresses.length; i++) {
                if (state.addresses[i].id == address.id) {
                    state.addresses.splice(i, 1)
                    return
                }
            }
        },
        SET_SERVICES(state, services) {
            state.services = services
        },
        SET_LOCALIZEDSERVICES(state, localizedServices) {
            state.localizedServices = localizedServices
        },
        SET_LOCALIZEDSERVICEIDS(state, localizedServiceIds) {
            state.localizedServiceIds = localizedServiceIds
        },
        SET_NEWS(state, news) {
            state.news = news
        },
        /* SET_SERVICE_CATEGORIES(state, serviceCategories) {
            state.serviceCategories = serviceCategories
        }, */
        SET_USER_CARD(state, card) {
            state.cards.push(card)
        },
        SET_USER_CARDS(state, cards) {
            state.cards = cards
        },
        SET_USER_ORDERS(state, orders) {
            state.orders = orders
        },
        SET_USER_SELECTED_ADDRESS(state, address) {
            state.selectedAddress = address
        },
        SET_USER_SELECTED_CARD(state, card) {
            state.selectedCard = card
        }
    },
    actions:{
        update({commit}, user) {
            commit('SET_USER', user);
        },
        updateAddress({commit}, address) {
            commit('SET_USER_ADDRESS', address)
        },
        setAddress({commit}, address) {
            commit('SET_USER_DEFAULT_ADDRESS', address)
        },
        setAddresses({commit}, addresses) {
            commit('SET_USER_ADDRESSES', addresses)
        },
        deleteAddress({commit}, address) {
            commit('DEL_USER_ADDRESS', address)
        },
        selectAddress({commit}, address) {
            commit('SET_USER_SELECTED_ADDRESS', address)
            let params = {
                street_number: address.streetNumber,
                street: address.street,
                postalCode: address.postalCode,
                locality: address.locality,
                country: address.country
            }
            // axios.get(`${process.env.VUE_APP_OURANOS_URL}/company/${this.state.user.user.companyId}/service?street_number=2&street=rue%20du%20chateau&postalCode=86340&locality=Vernon&country=France`)
            axios.get(`${process.env.VUE_APP_OURANOS_URL}/company/${this.state.user.user.companyId}/service`, { params: params})
            .then(response => {
                commit('SET_LOCALIZEDSERVICEIDS', response.data.map(item => item.id))
                commit('SET_LOCALIZEDSERVICES', response.data)
            })
            .catch(error => {
                console.error("failed to retrieve localized services")
                console.error(error)
            })
        },
        setServices({commit}, services) {
            commit('SET_SERVICES', services)
        },
        setLocalizedServices({commit}, localizedServices) {
            commit('SET_LOCALIZEDSERVICES', localizedServices)
        },
        setLocalizedServiceIds({commit}, localizedServiceIds) {
            commit('SET_LOCALIZEDSERVICEIDS', localizedServiceIds)
        },
        setCompanyNews({commit}, news) {
            commit('SET_NEWS', news)
        },
        setCard({commit}, card) {
            commit('SET_USER_CARD', card)
        },
        setCards({commit}, cards) {
            commit('SET_USER_CARDS', cards)
        },
        selectCard({commit}, card) {
            commit('SET_USER_SELECTED_CARD', card)
        },
        async loadAddresses({commit, dispatch}) {
            // Get addresses
            let addresses = await axios.get(`${process.env.VUE_APP_OURANOS_URL}/user/${this.state.user.user.id}/address`)
            addresses = addresses.data
            commit('SET_USER_ADDRESSES', addresses)
            for (let a = 0; a < addresses.length; a++) {
                if (addresses[a].default == 1) {
                    commit('SET_USER_DEFAULT_ADDRESS', addresses[a])
                    dispatch('user/selectAddress', addresses[a], {root: true})
                }
            }
            // Get services
            let services = await axios.get(`${process.env.VUE_APP_OURANOS_URL}/company/${this.state.user.user.companyId}/service`)
            services = services.data;
            let serviceCategories = []
            let processedCategoryIds = []
            let j = 0
            for (let i = 0 ; i < services.length; i++) {
                if (!processedCategoryIds.includes(services[i].category.id)) {
                    services[i].category.services = []
                    serviceCategories.push(JSON.parse(JSON.stringify(services[i].category)))
                    processedCategoryIds.push(services[i].category.id)
                }
                for (j = 0 ; j < serviceCategories.length; j++) {
                    if (serviceCategories[j].id == services[i].categoryId) {
                        serviceCategories[j].services.push(JSON.parse(JSON.stringify(services[i])))
                        break;
                    }
                }
            }
            commit('SET_SERVICES', serviceCategories)
        },
        async loadBankCards({commit}) {
            let response = await axios.get('/user/' + this.state.user.user.id + '/bankCard/')
            commit('SET_USER_CARDS', response.data)
            for (let a = 0; a < response.data.length; a++) {
                if (response.data[a].default == 1) {
                    commit('SET_USER_SELECTED_CARD', response.data[a])
                }
            }
        },
        async getOrders({commit}) {
            let orders = await axios.get(process.env.VUE_APP_OURANOS_URL + '/user/' + this.state.user.user.id + '/order');
            commit('SET_USER_ORDERS', orders.data)
            return orders.data
        }
    }
}