<template>
    <div class="cicle-icon-nav">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon" x="100%" y="100%" viewBox="0 0 15.907 17.475" style="enable-background:new 0 0 15.9 17.7;" xml:space="preserve">
            <g id="Icon_feather-shopping-bag" data-name="Icon feather-shopping-bag" transform="translate(-3.6 -2.1)">
                <path id="Tracé_48" data-name="Tracé 48" d="M6.851,3,4.5,6.135V17.107a1.567,1.567,0,0,0,1.567,1.567H17.04a1.567,1.567,0,0,0,1.567-1.567V6.135L16.256,3Z" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
                <path id="Tracé_49" data-name="Tracé 49" d="M4.5,9H18.607" transform="translate(0 -2.865)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
                <path id="Tracé_50" data-name="Tracé 50" d="M18.27,15A3.135,3.135,0,1,1,12,15" transform="translate(-3.581 -5.73)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'OrderIcon',
}
</script>

<style lang="scss" scoped>
.cicle-icon-nav {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon{
    fill:none;
    stroke:#000000;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    max-height: 17px;
}
</style>