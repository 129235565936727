<template>
    <header>
        <a href="/" class="navbar-brand">
            <img src="/images/companies/decathlon.png" alt="Fidensio">
        </a>
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item" :class="linkClass('services')">
                        <router-link :to="{path:'/services/list'}" class="nav-btn-link">
                            <ServiceIcon class="icon-nav"/>
                            <p class="nav-link">Vos Services</p>
                        </router-link>
                    </li>
                    <li class="nav-item" :class="linkClass('orders')">
                        <router-link :to="{path:'/orders'}" class="nav-btn-link">
                            <OrderIcon class="icon-nav"/>
                            <p class="nav-link">Vos demandes</p>
                        </router-link>
                    </li>
                    <li class="nav-item" :class="linkClass('account')">
                        <router-link :to="{path:'/account/information'}" class="nav-btn-link">
                            <UserIcon class="icon-nav"/>
                            <p class="nav-link">Votre compte</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="navbar-right">
            <!--<router-link :to="{path:'/'}" class="">
                <ResearchIcon class="icon-nav-research"/>
            </router-link>-->
            <!--<font-awesome-icon icon="sign-out" @click="logout()"/>-->
            <font-awesome-icon icon="qrcode" @click="showQrCode()"/>
        </div>
    </header>
</template>

<script>
import {mapActions} from 'vuex'
import OrderIcon from "@/components/Icon/OrderIcon";
import UserIcon from "@/components/Icon/UserIcon";
import ServiceIcon from "@/components/Icon/ServiceIcon";
// import ResearchIcon from "@/components/Icon/ResearchIcon";

export default {
    components: { UserIcon, ServiceIcon, OrderIcon },
        data(){
        return {}
    },
    methods:{
        ...mapActions({
            store_authLogout:"auth/logout"
        }),
        async logout() {
            this.store_authLogout()
            this.$router.push('/login')
        },
        showQrCode() {
            this.$emit('showQrCode')
        },
        linkClass(section) {
            switch(section) {
                case 'services':
                    if (this.$route.path == '/') return 'active'
                    if (this.$route.path.includes('/service')) return 'active'
                    return ''
                case 'orders':
                    if (this.$route.path.includes('/orders')) return 'active'
                    return ''
                case 'account':
                    if (this.$route.path.includes('/account')) return 'active'
                    return ''
            }
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>

header {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 97;
    height: 70px;
    width: 100%;
    padding: 0 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px $c-3-500;
    box-shadow: 0px 0px 10px $c-3-400;

    .navbar-brand img {
        max-height: 35px;
    }
    .navbar-brand {
        max-height: 35px;
        width: 150px;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
            display: inline;
        }
    }

    .navbar-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
}

.active {
    .nav-btn-link .cicle-icon-nav {
        background-color: $c-2-300;
    }
}

.nav-btn-link {
    display: flex;
    align-items: center;
    justify-content: center;
    .cicle-icon-nav {
        height: 36px;
        width: 36px;
        margin: 0 12px;
    }

    &:hover {
        .cicle-icon-nav {
            border: 1px solid $c-2-400;
            background-color: $c-2-300;
            .icon {
                height: 14px;
                stroke: $c-0-800;
            }
        }
    }
}


/* iPads (landscape) ----------- */
@media only screen and (max-device-width : 1280px) {

}

/* iPads (portrait) ----------- */
@media screen and (max-width : 980px) {
    nav.navbar {
        display: none;
    }
}



</style>