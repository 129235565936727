<template>
    <div class="modal-container">
        <header class="modal-header">
            <slot name="header">
                <h1>Planifier le dépôt</h1>
                <button type="button" class="btn-close" @click="closeModal" aria-label="Close modal">x</button>
            </slot>
        </header>

        <section class="modal-body">
            <slot name="body">
                <div v-if="step == 1">
                    <h2>Choisissez l'adresse dépôt</h2>
                    <ul class="step-container">
                        <li v-for="address in addresses" :key="address.id" class="address" :class="address.id == selectedAddressId ? 'selected' : ''" v-on:click="selectAddress(address.id)">
                            <span>
                                <font-awesome-icon icon="location" />
                                <span>
                                    {{ address.name }}
                                    <br>
                                    {{  address.streetNumber }} {{ address.street }}
                                    <br>
                                    {{  address.postalCode }} {{  address.locality }}
                                </span>
                            </span>
                            <font-awesome-icon icon="check" v-if="address.id == selectedAddress.id" class="white" />
                        </li>
                    </ul>
                </div>

                <div v-if="step == 2">
                    <h2>Choisissez un créneau horaire</h2>
                    <ul>
                        <li v-for="(date, idx) in dates" :key="idx" class="date" :class="idx == selectedDateIdx ? 'selected' : ''" v-on:click="selectDate(idx)">
                            <span>
                                <font-awesome-icon icon="calendar" />
                                <span>
                                    {{ date.label }}
                                </span>
                            </span>
                            <font-awesome-icon icon="check" v-if="idx == selectedDateIdx" class="white" />
                        </li>
                    </ul>
                </div>

                <div v-if="step == 3">
                    <h2>Choisissez un créneau horaire</h2>
                    <ul>
                        <li v-for="(time, idx) in dates[selectedDateIdx].slots" :key="idx" class="date" :class="idx == selectedTimeIdx ? 'selected' : ''" v-on:click="selectTime(idx)">
                            <span>
                                <font-awesome-icon icon="clock" />
                                <span>
                                    {{ time.start.split(':')[0] }} : {{ time.start.split(':')[1] }} -
                                    {{ time.end.split(':')[0] }} : {{ time.end.split(':')[1] }}
                                </span>
                            </span>
                            <font-awesome-icon icon="check" v-if="idx == selectedTimeIdx" class="white" />
                        </li>
                    </ul>
                </div>
            </slot>
        </section>

        <footer class="modal-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <router-link to="/account/addresses" v-if="this.step == 1">Gérer vos addresses</router-link>
                    <a v-else href="#" @click.prevent="stepBack()">Revenir en arrière</a>
                    <button class="btn btn-primary btn-loader" :class="!isSubmitEnabled ? 'disabled' : ''" :disabled="!isSubmitEnabled" v-on:click="submit">
                        <img v-if="loading" class="loader" src="@/assets/img/loader.gif">
                        <span v-if="!loading">{{ btnLabel }}</span>
                        <span v-else>Chargement...</span>
                    </button>
                </div>
            </slot>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: ['params'],
    emits: [
        'close'
    ],
    data() {
        return {
            loading: false,
            step: 1,
            dates: [],
            selectedAddressId: 0,
            selectedAddress: {},
            selectedDateIdx: -1,
            selectedTimeIdx: -1
        }
    },
    watch: {
        params() {
            this.step = 0
            this.selectedAddressId = '';
            this.selectedDateIdx = -1;
            this.selectedTimeIdx = -1;
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            addresses: state => state.user.addresses
        }),
        btnLabel() {
            if (this.step == 1) return "Suivant"
            return "Valider"
        },
        isSubmitEnabled() {
            if (this.step == 1) {
                if (this.selectedAddressId > 0) return true;
                return false;
            }
            if (this.step == 2) {
                if (this.selectedDateIdx >= 0) return true;
                return false;
            }
            if (this.step == 3) {
                if (this.selectedTimeIdx >= 0) return true;
                return false;
            }
            return false
        }
    },
    mounted() {},
    methods: {
        ...mapActions({
            updateSelectedAddress: 'user/selectAddress'
        }),
        selectAddress(addressId) {
            this.selectedAddressId = addressId
        },
        selectDate(dateIdx) {
            this.selectedDateIdx = dateIdx
        },
        selectTime(timeIdx) {
            this.selectedTimeIdx = timeIdx
        },
        stepBack() {
            if (this.step == 2) {
                this.step = 1
            }
        },
        closeModal() {
            this.$emit('close');
        },
        submit() {
            if (this.step == 1) {
                this.step = 2
                // console.log(this.addresses)
                this.selectedAddress = this.addresses.filter( addr => addr.id == this.selectedAddressId).pop()
                console.log(this.selectedAddress)
                this.axios.get('/service/pressingAtHome/slots?postalCode=' + this.selectedAddress.postalCode)
                .then(({data}) => {
                    for (let i = 0; i < data.slots.length; i++) {
                        this.dates.push({
                            key: data.slots[i].date,
                            label: this.moment(data.slots[i].date).locale('fr-FR').format('LL'),
                            slots: data.slots[i].slots
                        })
                    }
                    console.log(this.dates)
                }).catch(error => {
                    console.log(error)
                    // this.emitter.emit('error', "Une erreur serveur est survenue")
                    throw error
                })
                return
            }
            if (this.step == 2) {
                this.step = 3
                return
            }
            if (this.step == 3) {
                this.loading = true
                let mobile = this.user.mobile
                while(mobile.charAt(0) === '0') {
                    mobile = mobile.substring(1);
                }
                let data = {
                    streetNumber: this.selectedAddress.streetNumber,
                    street: this.selectedAddress.street,
                    locality: this.selectedAddress.locality,
                    postalCode: this.selectedAddress.postalCode,
                    country: "France",
                    firstName: this.user.data.firstName,
                    lastName: this.user.data.lastName,
                    indicMobile: this.user.indicMobile,
                    mobile: mobile,
                    date: this.dates[this.selectedDateIdx].key,
                    minTime: this.dates[this.selectedDateIdx].slots[this.selectedTimeIdx].start,
                    maxTime: this.dates[this.selectedDateIdx].slots[this.selectedTimeIdx].end
                }
                console.log(data)
                this.axios.put(`/service/pressingAtHome/${this.params.order.id}/delivery`, data)
                .then((data) => {
                    console.log(data)
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                    // this.emitter.emit('error', "Une erreur serveur est survenue")
                    throw error
                }).finally(() => {
                    this.emitter.emit('reloadOrder', this.params.order.id)
                    this.closeModal()
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step-container {
    margin-top: 50px;
}
li.address,
li.date {
    display: flex;
    justify-content: space-between;
    svg {
        margin-right: 10px;
    }
    text-align: left;
    background-color: #F3F1F0;
    border-radius: 15px;
    width: 94%;
    padding: 16px 20px;
    margin: 16px auto;
}
li.address:hover,
li.address.selected,
li.date:hover,
li.date.selected {
    background-color: #AEEBE0;
    border: solid 1px #00C3A0
}
.white {
    color: white;
}

.footer-buttons {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

.btn-primary {
    margin: 0;
}

.btn-primary.disabled,
.btn-primary.disabled:hover {
    background-color: darkgray;
    border: none;
}
</style>