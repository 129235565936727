export default {
    namespaced: true,
    state: {
        company: {}
    },
    getters: {
        company(state) {
            return state.company
        }
    },
    mutations: {
        SET_COMPANY (state, value) {
            state.company = value
        }
    },
    actions:{
        update({commit}, company) {
            commit('SET_COMPANY', company);
        }
    }
}