<template>
    <div class="cicle-icon-nav">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" x="100%" y="100%" viewBox="0 0 20.077 16.796" style="enable-background:new 0 0 15.9 17.7;" xml:space="preserve">
            <g id="Groupe_241" data-name="Groupe 241" transform="translate(-581.163 -26.397)">
                <path id="Icon_awesome-concierge-bell" data-name="Icon awesome-concierge-bell" d="M10.281,7.769a7.238,7.238,0,0,0-1.117-.1A8.38,8.38,0,0,0,8,7.769a8.119,8.119,0,0,0-6.854,8.09H17.135A8.119,8.119,0,0,0,10.281,7.769ZM0,19.7c0,.007,18.281.084,18.277.095C18.3,19.822,0,19.713,0,19.7Z" transform="translate(582.063 22.498)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
            <path id="Icon_awesome-concierge-bell-2" data-name="Icon awesome-concierge-bell" d="M9.164,4.672ZM0,19.575c0,.007,18.281.082,18.277.093C18.3,19.694,0,19.588,0,19.575Z" transform="translate(582.063 22.625)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8"/>
        </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'ServiceIcon',
}
</script>

<style lang="scss" scoped>
.cicle-icon-nav {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon{
    fill:none;
    stroke:#000000;
    stroke-width:2;
    stroke-linecap:round;
    stroke-linejoin:round;
    height: 17px;
}
</style>