import { createRouter, createWebHistory } from 'vue-router'

import auth from '../middleware/auth'

import store from '@/store'; // <-- aliased path

/* Layouts */
const AccountLayout = () => import('../layouts/AccountLayout.vue')
// const AppLayout = () => import('../layouts/AppLayout.vue')
const GuestLayout = () => import('../layouts/GuestLayout.vue')
const ServiceLayout = () => import('../layouts/ServiceLayout.vue')
const ServicesLayout = () => import('../layouts/ServicesLayout.vue')
const OrderLayout = () => import('../layouts/OrderLayout.vue')

/* Auth Pages */
const Login = () => import('../pages/auth/Login.vue')
const Forgot = () => import('../pages/auth/Forgot.vue')
const Renew = () => import('../pages/auth/Renew.vue')
const Logout = () => import('../pages/auth/Logout.vue')
const Register = () => import('../pages/auth/Register.vue')
const Validate = () => import('../pages/auth/Validate.vue')
/* Main Pages */
// const Home = () => import('../pages/Home.vue')

/* Account Pages */
const Account = () => import('../pages/account/Account.vue')
const Information = () => import('../pages/account/Information.vue')
const Address = () => import('../pages/account/Address.vue')
const BankCard = () => import('../pages/account/BankCard.vue')
const QRCode = () => import('../pages/account/QRCode.vue')

/* Service Pages */
const ServiceList = () => import('../pages/services/List.vue')

const NotFound = () => import('../pages/NotFound.vue')

const routes = [
    {
        path:"/auth",
        component:GuestLayout,
        meta:{
            middleware:"guest"
        },
        children:[
            {
                path:"/login",
                component:Login,
                meta: {
                    middleware: "guest",
                }
            },
            {
                path:"/forgot",
                component:Forgot,
                meta: {
                    middleware: "guest",
                }
            },
            {
                path: "/reset-password",
                component: Renew,
                meta: {
                    middleware: "guest"
                }
            },
            {
                path:"/register",
                component:Register,
                meta: {
                    middleware: "guest",
                }
            },
            {
                path:"/validate",
                component:Validate,
                meta: {
                    middleware: "guest",
                }
            },
        ]
    },
    {
        path:"/logout",
        component:Logout
    },
    {
        path:"/",
        component:ServicesLayout,
        meta:{
            middleware:"auth"
        },
        children:[
            {
                path: '/',
                component: ServiceList
            }
        ]
    },
    {
        path:"/account",
        component:AccountLayout,
        meta:{
            middleware:"auth"
        },
        children:[
            {
                path: '',
                component: Account
            },
            {
                path: 'information',
                component: Information
            },
            {
                path: 'addresses',
                component: Address
            },
            {
                path: 'bankcards',
                component: BankCard
            },
            {
                path: 'qrcode',
                component: QRCode
            }
        ]
    },
    {
        path:"/services",
        component:ServicesLayout,
        meta:{
            middleware:"auth"
        },
        children:[
            {
                path: 'list',
                component: ServiceList
            }
        ]
    },
    {
        path:"/service",
        component:ServiceLayout,
        meta:{
            middleware:"auth"
        },
        children:[
            {
                path: ':service(.*)',
                component: () => import('../pages/service/Service.vue')
            }
        ]
    },
    {
        path:"/orders",
        component:OrderLayout,
        meta:{
            middleware:"auth"
        }
    },
    {
        path:"/:pathMatch(.*)*",
        component:NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // document.title = "Fidensio"
    if (to.meta.middleware=="guest") {
        console.log("Router middleware : guest")
        if(store.getters['auth/authenticated']) {
            console.log("redirect to /")
            next({path:"/"})
        } else {
            // console.log("next()")
            next()
        }
    } else if (to.meta.middleware=="auth") {
        // console.log("Router middleware : not guest")
        if (auth()) {
            next()
        } else {
            console.log('redirect to /login')
            next({path:"/login"})
        }
        /* if (store.getters['auth/authenticated']) {
            // console.log("next()")
            next()
        } else {
            console.log('redirect to /login')
            next({path:"/login"})
        } */
    } else {
        next()
    }
})

export default router